import React from "react"
import { Link } from 'gatsby'

import DesignImg from "../../../assets/images/services_yellow_icons/design.svg"
import StrategyImg from "../../../assets/images/services_yellow_icons/strategy.svg"
import WebsiteImg from "../../../assets/images/services_yellow_icons/website.svg"
import MobileImg from "../../../assets/images/services_yellow_icons/mobile.svg"
import SeoImg from "../../../assets/images/services_yellow_icons/seo.svg"
import SemImg from "../../../assets/images/services_yellow_icons/sem.svg"
import OutsourcingImg from "../../../assets/images/services_yellow_icons/outsourcing.svg"
import Software from "../../../assets/images/services_yellow_icons/development.svg"
import arrow from "../../../assets/images/arrow.png"

export default () =>
<div>
<div className="services-left">
            <div id="link-box-services">
                <Link className="design" to="/services/design">
                    <img src={DesignImg} alt="Drupal and WordPress Development Company, ICON Worldwide, Design" title="Drupal and WordPress Development Company, ICON Worldwide, Design"/>
                    <h2 className="type">Design</h2>
                    <div className="menuTextInnerServices">
                        <h3>Design</h3>
                        <p>Design is the foundation of everything we do. Ouraward-winning team of designers are passionate
                        about their work, and work across all mediums, from print to digital to animation.</p>
                    </div>
                </Link>
                <Link className="strategy" to="/services/marketing-strategy
">
                    <img src={StrategyImg} alt="Drupal and WordPress Development Company, ICON Worldwide, strategy" title="Drupal and WordPress Development Company, ICON Worldwide, strategy"/>
                    <h2 className="type">Strategy</h2>
                    <div className="menuTextInnerServices">
                        <h3>Strategy</h3>
                        <p>Everything we do starts with strategy. We understand strategy on a continuum, andwork tohelp brands evolve,
                        capture the next opportunity and conquer the next challenge.</p>
                    </div>
                </Link>
                <Link className="website" to="/services/website-development">
                    <img src={WebsiteImg} alt="Drupal and WordPress Development Company, ICON Worldwide, website" title="Drupal and WordPress Development Company, ICON Worldwide, website"/>
                    <h2 className="type">Website</h2>
                    <div className="menuTextInnerServices">
                        <h3>Website Development</h3>
                        <p>User-centric design and cutting-edge technology combine to build effective websites. We are expert Drupal,
                        Magnolia CMS, Wordpress, React, Java and Angular developers.</p>
                    </div>
                </Link>
                <Link className="mobile" to="/services/mobile-development">
                    <img src={MobileImg} alt="Drupal and WordPress Development Company, ICON Worldwide, mobile" title="Drupal and WordPress Development Company, ICON Worldwide, mobile"/>
                    <h2 className="type">Mobile</h2>
                    <div className="menuTextInnerServices">
                        <h3>Mobile Apps</h3>
                        <p>Since 2007 we have built over 500 mobile apps for startup ventures to world leading enterprises. We know the importance of design and technology,
                        and this interdisciplinary collaboration is the key to innovative products. </p>
                    </div>
                </Link>
                <Link className="sofware" to="/services/software-development">
                    <img src={Software} alt="Drupal and WordPress Development Company, ICON Worldwide, development" title="Drupal and WordPress Development Company, ICON Worldwide, development"/>
                    <h2 className="type">Software</h2>
                    <div className="menuTextInnerServices">
                        <h3>Custom Software Development</h3>
                        <p>Blockchain, AI and IoT are transforming all industries, our expert developers work with you to realization the potential of
                        these exciting new technologies.</p>
                    </div>
                </Link>
                <Link className="it-outsourcing" to="/services/nearshore-software-development">
                    <img src={OutsourcingImg} alt="Drupal and WordPress Development Company, ICON Worldwide, nearshore" title="Drupal and WordPress Development Company, ICON Worldwide, nearshore"/>

                    <h2 className="type">Nearshore</h2>
                    <div className="menuTextInnerServices">
                        <h3>Nearshore Development</h3>
                        <p>Our development centre in Sofia, Bulgaria provides clients with expert development talent across a range of technologies.</p>
                    </div>
                </Link>
                <Link className="seo" to="/services/seo">
                    <img src={SeoImg} alt="Drupal and WordPress Development Company, ICON Worldwide, seo" title="Drupal and WordPress Development Company, ICON Worldwide, seo"/>
                    <h2 className="type">SEO</h2>
                    <div className="menuTextInnerServices">
                        <h3>Search Engine Optimisation</h3>
                        <p>SEO is about delivering content that search engines want to reward and that is what drives customers to your site. SEO is about being found
                        in all the right places online, and it’s about using that visibility to drive real, measurable growth in your company’s bottom line.</p>
                    </div>
                </Link>
                <Link className="sem" to="/services/search-engine-marketing">
                    <img src={SemImg} alt="Drupal and WordPress Development Company, ICON Worldwide, sem" title="Drupal and WordPress Development Company, ICON Worldwide, sem"/>
                    <h2 className="type">SEM</h2>
                    <div className="menuTextInnerServices">
                        <h3>Search Engine Marketing</h3>
                        <p>Google Adwords and PPC campaigns on platforms such as Twitter, Instagram, LinkedIn and Facebook are an essential tool to reaching your precise target audience.</p>
                    </div>
                </Link>
            </div>
        </div>
        <div className="services-right">
            <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <h1>Web and Software Development for your Enterprise's Goals</h1>
            <p>The integration of strategy, design and technology is the key to innovation.</p>
        </div>
    </div>
