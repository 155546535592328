import React from "react"
import { Scroller, Section } from 'react-fully-scrolled';
import SEO from "../components/seo"
import ServiceMenu from "../components/services/servicesMenuHeader"
import ServicesPage from "../components/services/servicesPage"
import Footer from "../components/footer"
import CookieBar from "../components/cookieBar"

export default () =>
 <div> 
    <SEO title={'ICON Worldwide | Services - Web and Software Development'} 
    description="Try the power of the most advanced web and software development resources: rely on our team of Drupal, Acquia, and Magnolia certified developers!"
    canonical={'https://icon-worldwide.com/services'} />
    <CookieBar/>
    <Scroller
    curPage={1}
    onBeforeScroll={(from, to) => {}}
    onAfterScroll={(page) => {}}
    isEnabled={true}>
    <Section>
            <div id="services">
                <ServiceMenu/>
                <ServicesPage/>
            </div>
        </Section>
        <Section>
            <Footer noScrollbar="false"/>
        </Section>
        </Scroller>
</div>